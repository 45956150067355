import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import NavBar from '../components/nav-bar';
import { useDispatch, useSelector } from 'react-redux';
import { isExpired } from '../utils/jwt.util';
import { clearUser } from '../lib/redux/slices/auth-user.slice';
import { googleLogout } from '@react-oauth/google';
import { asyncLocalStorage, AUTH_TOKEN_KEY } from '../lib/local-storage';
import { getNewTokensAndUpdateUserStore } from '../utils/get-new-tokens-and-update-user-store';

const ProtectedRoutes = () => {    
    const location = useLocation();
    const dispatch = useDispatch();
    const authUser = useSelector((store) => store.authUser);

    const logout =  async () => {
        googleLogout()
        dispatch(
            clearUser()
        )
        await asyncLocalStorage.deleteItem(AUTH_TOKEN_KEY)
    }

    if(!authUser.isAuthenticated) {
        return <Navigate to="/login" state={{prevUrl: location.pathname}}/>
    }

    if(isExpired(authUser.refreshToken)) {
        logout()
    }

    if(isExpired(authUser.accessToken)) {
        getNewTokensAndUpdateUserStore(dispatch, authUser.refreshToken)
    }

    if(!authUser.accessGranted) {
        return (
            <div>
                <NavBar/>
                <div className="flex flex-col items-center justify-center h-96">
                    <div className="tex-[#000929] text-base font-medium">
                        Your account has not been activated, yet.
                    </div>
                    <div className="tex-[#000929] text-base font-medium">
                        Please reach out to the <b>Admin</b>, for the activation of the account.
                    </div>
                </div>
            </div>
        )
    }

    return (
    <div>
        <NavBar/>
        <Outlet/>
    </div>
    )
};

export default ProtectedRoutes;