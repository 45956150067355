import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: undefined,
  refreshToken: undefined,
  userType: undefined,
  accessGranted: false,
  userId: undefined,
  isAuthenticated: false
};

const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userType = action.payload.userType;
      state.accessGranted = action.payload.accessGranted
      state.userId = action.payload.userId
      state.isAuthenticated = action.payload.isAuthenticated
    },
    updateTokens: (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
    },
    updateAccessGranted: (state, action) => {
      if(state.userId === action.payload.userId) {
        state.accessGranted = action.payload.accessGranted;
      }
    },
    clearUser: () => initialState,
    clearTokens: (state, action) => {
        state.accessToken = undefined;
        state.refreshToken = undefined;
    }
  },
});

export const { updateUser, updateTokens, clearUser, clearTokens, updateAccessGranted } = authUserSlice.actions;

export default authUserSlice.reducer;