import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoutes = () => {
    const location = useLocation();
    const authUser = useSelector((store) => store.authUser);
    
    if(authUser.isAuthenticated) {
        const url = location.state?.prevUrl !== '/login' ? location.state?.prevUrl : '/'
        return <Navigate to={url} state={{prevUrl: location.pathname}}/>
    }

    return (
    <div>
        <Outlet/>
    </div>
    )
};

export default PublicRoutes