const NoContentContainer = ({text}) => {
    return (
        <div className="flex flex-1 flex-col gap-3 mt-5 mb-5 sticky top-0 h-[97vh]">
            <div className="flex justify-between items-center pr-5 py-5 bg-white">
                <div className="text-base font-semibold"></div>
                <div></div>
            </div>
            <div className="mt-5 border border-dashed flex justify-center flex-1">
                <div className="mt-16">{text}</div>
            </div>
        </div> 
    )
}

export default NoContentContainer