import {  useEffect, useRef, useState } from 'react';
import { getBackgroundColorForPercentage, getColorForPercentage } from '../../utils';
import { toast } from 'react-toastify';
import AxiosInstance from '../../lib/axios/axios-instance';
import { getFragmentaryResponse } from '../../components/fragmentary-response';

const axiosInstance = AxiosInstance.getInstance()

const TitleMapping = {
    RequirementCoverage:'Requirement Coverage',
    ContextualAccuracy:'Contextual Accuracy',
    ProjectLifecycleCoverage:'Project Lifecycle Coverage',
    EpicClarityAndScope:'Epic Clarity And Scope'
}

const hasOppositePercentageColor = ['Hallucination', 'Toxicity']

  const DocumentAnalysisCard = ({data, name}) => {
    const divRef = useRef(null)
    const [showDetails, setShowDetails] = useState(false)
    const [width, setWidth] = useState(0)

    const toggle = () => setShowDetails(!showDetails)
    const scorePercentage = (data.score * 100).toFixed(2)

    useEffect(() => {
        if(!divRef?.current) return
        setWidth((divRef.current.clientWidth / 100) *  scorePercentage)
    }, [divRef])

    const isOpposite = hasOppositePercentageColor.includes(name)
    const backgroundcolor = getBackgroundColorForPercentage(scorePercentage,isOpposite)
    const color = getColorForPercentage(scorePercentage,isOpposite)
    const title = TitleMapping[name] !== undefined ? TitleMapping[name] : name
    return (
        <div>
            <div onClick={toggle} role="button">
             <div className="capitalize text-base font-semibold">{title} ({scorePercentage}%)</div>

             <div style={{backgroundColor: backgroundcolor}} ref={divRef} className="w-full h-5 mt-3">
                <div style={{backgroundColor: color, width: `${width}px`}} className="h-full" />
             </div>
             </div>
             
             {showDetails && <div className="flex flex-col gap-3 mt-3">
                <div className="flex flex-col justify-center">
                    <div className="font-medium text-base">Reason:</div>
                    <div className="font-light text-sm text-[#828282]">{data.reason}</div>
                </div>
            </div>}
        </div>
    )
}

const getMetricAnalysisReport = (documentAnalysis) => {
    return Object.keys(documentAnalysis).map((key) => <DocumentAnalysisCard key={key} name={key} data={documentAnalysis[key]}/>)
}
  

const Metric = () => {
    const [projectName, setProjectName] = useState('')
    const [generating, setGenerating] = useState(false)
    const [checkingResult, setCheckingResult] = useState(false)
    const [loadingFragmentaryResponse, setLoadingFragmentaryResponse] = useState(false)

    const [metricResponse, setMetricResponse] = useState(null)
    const [fragmentaryResponse, setFragmentaryResponse] = useState(null)

    const checkResult = async () => {
        setMetricResponse(null)
        try {
            setCheckingResult(true)
            const response = await axiosInstance.get("/check-metrics-result", {
                params: {
                    project_name: projectName
                }
            })

            setMetricResponse(response?.data)
            setCheckingResult(false)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setCheckingResult(false)
        } 
    }

    const generate = async () => {
        try {
            setGenerating(true)
            const response = await axiosInstance.get("/generate/metrics", {
                params: {
                    project_name: projectName
                }
            })

            toast.success(response?.data)
            setGenerating(false)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setGenerating(false)
        } 
    }

    const getPDFFragmentaryResponse = async () => {
        try {
            setLoadingFragmentaryResponse(true)
            setFragmentaryResponse(null)

            const response = await axiosInstance.get("/check-dmetrics-result", {
                params: {
                    project_name: projectName
                }
            })

            setFragmentaryResponse(response?.data)
            setLoadingFragmentaryResponse(false)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setLoadingFragmentaryResponse(false)
            setFragmentaryResponse(null)
        } 
    }

    return (
        <div className="px-10 mb-5">
            <div className="flex gap-3 my-5">
                <div className="w-60"><input name="projectName" type="text" className="w-full border-b-2 py-4 focus:border-[#0D61F7]" placeholder="Project Name" onChange={(e)=>setProjectName(e.target.value)} value={projectName}/></div>
                <div>
                    <button  onClick={generate} disabled={!projectName} className="bg-[#0D61F7] rounded-2xl px-6 py-4 text-white disabled:bg-[#f3f3f4] disabled:text-[#8D8A95]">
                        {generating ? 'Generating...' : 'Generate Metrics'}
                    </button>
                </div>
            </div>

            <div className="flex my-1 items-center justify-end gap-3">
                <div>
                    <button onClick={checkResult} disabled={!projectName || checkingResult} className="bg-[#0D61F7] rounded-2xl px-6 py-4 text-white disabled:bg-[#f3f3f4] disabled:text-[#8D8A95]">
                        {checkingResult ? 'Checking...' : 'Check Metrics' }
                    </button>
                </div>
                <div>
                    <button onClick={getPDFFragmentaryResponse} disabled={!projectName || loadingFragmentaryResponse} className="bg-[#0D61F7] rounded-2xl px-6 py-4 text-white disabled:bg-[#f3f3f4] disabled:text-[#8D8A95]">
                        {loadingFragmentaryResponse ? 'Getting Response...' : 'Get PDF check Metrics' }
                    </button>
                </div>
            </div>

            {metricResponse && (
            <div>
                <div className="bg-[#F2F2F2] relative mt-5 py-3 px-3 flex flex-col justify-center gap-3">
                    <div className="text-base font-semibold">Epic Metrics Result:</div>
                </div>
                
                <div className="bg-[#F2F2F2] relative py-3 px-3 grid grid-cols-3 justify-center gap-3">
                    {getMetricAnalysisReport(metricResponse.epic_metrics_result)}
                </div>
                

                <div className="bg-[#F2F2F2] relative mt-5 py-3 px-3 flex flex-col justify-center gap-3">
                    <div className="text-base font-semibold">Task Metrics Result:</div>
                </div>
                
                <div className="bg-[#F2F2F2] relative py-3 px-3 grid grid-cols-3 justify-center gap-3">
                    {getMetricAnalysisReport(metricResponse.task_metrics_result)}
                </div>
            </div>
            )}

            {fragmentaryResponse && getFragmentaryResponse(fragmentaryResponse)}
        </div>
    )
}

export default Metric