import RootRoute from "./routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { appStore, persistor } from "./lib/redux/store/app.store"
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <Provider store={appStore}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <RootRoute />
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
