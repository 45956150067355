import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import NavBar from '../components/nav-bar';
import { useDispatch, useSelector } from 'react-redux';
import { isExpired } from '../utils/jwt.util';
import { clearUser } from '../lib/redux/slices/auth-user.slice';
import { googleLogout } from '@react-oauth/google';
import { getNewTokensAndUpdateUserStore } from '../utils/get-new-tokens-and-update-user-store';


const AdminRoutes = () => {    
    const location = useLocation();
    const dispatch = useDispatch();
    const authUser = useSelector((store) => store.authUser);

    const logout = () => {
        googleLogout()
        dispatch(
            clearUser()
        )
    }

    

    if(!authUser.isAuthenticated) {
        return <Navigate to="/login" state={{prevUrl: location.pathname}}/>
    }
    
    if(isExpired(authUser.refreshToken)) {
        logout()
    }

    if(isExpired(authUser.accessToken)) {
        getNewTokensAndUpdateUserStore(dispatch, authUser.refreshToken)
    }

    if(authUser.userType !== "admin" || !authUser.accessGranted) {
        return <Navigate to="/" state={{prevUrl: location.pathname}}/>
    }

    return (
    <div>
        <NavBar/>
        <Outlet/>
    </div>
    )
};

export default AdminRoutes;