import {
    BrowserRouter,
    Route,
    Routes
  } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Metric from "../pages/metric";
import ViewFeedback from "../pages/view-feedback";
import Login from "../pages/login";
import PublicRoutes from "./public.route";
import ProtectedRoutes from "./protected.route";
import AdminRoutes from "./admin.route";
import AdminDashboard from "../pages/admin-dashboard";

const RootRoute = () => {
    return (
        <BrowserRouter basename="/">
            <ToastContainer />
            <Routes>
                <Route path="/login" element={<PublicRoutes/>}>
                    <Route path="/login" Component={Login} />
                </Route>
                <Route path="/" element={<ProtectedRoutes/>}>
                    <Route path="/" Component={Dashboard} exact/>
                    <Route path="/metric" Component={Metric} />
                </Route>
                <Route path="/admin" element={<AdminRoutes/>}>
                    <Route exact path="/admin" Component={AdminDashboard} />
                    <Route exact path="/admin/feedback" Component={ViewFeedback} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default RootRoute