import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/GoGroup.png'
import BrandLogo from '../../assets/brand-logo.png'
import { googleLogout } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { clearUser } from '../../lib/redux/slices/auth-user.slice';
import { asyncLocalStorage, AUTH_TOKEN_KEY } from '../../lib/local-storage';

const NavBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateTo = (path) => navigate(path)

    const logout = async () => {
        googleLogout()
        dispatch(
            clearUser()
        )
        await asyncLocalStorage.deleteItem(AUTH_TOKEN_KEY)
    }

    return (<nav className="flex items-center pr-5">
        <div className="w-1/2">
            <button className="w-[200px] h-[50px] m-1" onClick={() => navigateTo('/')}>
                <img className="w-full h-full" src={Logo} alt="Logo"/>
            </button>
        </div>

        <div className="w-1/2 text-xl font-bold">
            <div className="h-[70px] m-1">
                <img className="h-full" src={BrandLogo} alt="Brand"/>
            </div>
        </div>
        <div>
            <button onClick={logout}>Logout</button>
        </div>
    </nav>)
}

export default NavBar