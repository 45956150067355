export const getBackgroundColorForScore = (score) => {
    if(score <= 3 && score >= 0) {
        return "#ff000059"
    } else if(score <= 6 && score >= 4) {
        return "#ffc21066"
    } else {
        return "#068c064f"
    }
  }

export const getColorForScore = (score) => {
    if(score <= 3 && score >= 0) {
        return "#ff0000"
    } else if(score <= 6 && score >= 4) {
        return "#ffc210"
    } else {
        return "#068c06"
    }
  }

  export const getBackgroundColorForPercentage = (percentage, isOpposite = false) => {
    if(isOpposite) {
        if(Number(percentage) <= 30 && Number(percentage) >= 0) {
            return getBackgroundColorForScore(10)
        } else if(Number(percentage) <= 60 && Number(percentage) > 30) {
            return getBackgroundColorForScore(6)
        } else {
            return getBackgroundColorForScore(3)
        }
    }
    
    if(Number(percentage) <= 30 && Number(percentage) >= 0) {
        return getBackgroundColorForScore(3)
    } else if(Number(percentage) <= 60 && Number(percentage) > 30) {
        return getBackgroundColorForScore(6)
    } else {
        return getBackgroundColorForScore(10)
    }
  }

export const getColorForPercentage = (percentage, isOpposite = false) => {
    if(isOpposite) {
        if(Number(percentage) <= 30 && Number(percentage) >= 0) {
            return getColorForScore(10)
        } else if(Number(percentage) <= 60 && Number(percentage) > 30) {
            return getColorForScore(6)
        } else {
            return getColorForScore(3)
        }
    }

    if(Number(percentage) <= 30 && Number(percentage) >= 0) {
        return getColorForScore(3)
    } else if(Number(percentage) <= 60 && Number(percentage) > 30) {
        return getColorForScore(6)
    } else {
        return getColorForScore(10)
    }
  }