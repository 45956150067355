import {  GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import BrandLogo from '../../assets/brand-logo.png'
import { useDispatch } from 'react-redux';
import { updateUser } from '../../lib/redux/slices/auth-user.slice';
import { jwtDecode } from 'jwt-decode';
import AxiosInstance from '../../lib/axios/axios-instance';
import { useState } from 'react';
import { asyncLocalStorage, AUTH_TOKEN_KEY } from '../../lib/local-storage';

const axiosInstance = AxiosInstance.getInstanceWithoutToken()

const getPayload = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);

    return {
        email: decoded.email,
        username: decoded.given_name + '-' + decoded.family_name,
        first_name: decoded.given_name,
        last_name: decoded.family_name,
        profile_picture_url: decoded.picture,
        sub: decoded.sub,
        email_verified: decoded.email_verified,
        access_token: credentialResponse.credential
      }
}

const Login = () => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const onError = () => {
        toast.error('Login failed.')
    }

    const onSuccess = async (credentialResponse) => {
        await signIn(credentialResponse)
    }

    const signIn = async (credentialResponse) => {
        try {
            setIsLoading(true)
            const requestBody = getPayload(credentialResponse)

            const response = await axiosInstance.post("/google-signin", requestBody)

            await asyncLocalStorage.setItem(AUTH_TOKEN_KEY, response.data.access_token)
            dispatch(
                updateUser({
                    accessToken: response.data.access_token,
                    refreshToken: response.data.refresh_token,
                    userType: response.data.user_type,
                    accessGranted: response.data?.access_granted ? true : false,
                    userId: response.data._id,
                    isAuthenticated: true
                 })
            )

            toast.success("Login successful.")
            setIsLoading(false)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setIsLoading(false)
        } 
    }

    return (
    <div className="flex flex-col items-center justify-center h-[87vh] gap-3">
        <div className="flex items-center justify-center">
            <div className="text-xl font-bold">
                <div className="h-[70px] m-1">
                    <img className="h-full" src={BrandLogo} alt="Brand"/>
                </div>
            </div>
        </div>

        <div>
        <GoogleLogin
            onSuccess={onSuccess}
            onError={onError}
            useOneTap
            auto_select
        />        
        </div>
    </div>)
}

export default Login