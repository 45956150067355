import AxiosInstance from "../lib/axios/axios-instance"
import { updateUser } from "../lib/redux/slices/auth-user.slice"
import { toast } from 'react-toastify';
import { asyncLocalStorage, AUTH_TOKEN_KEY } from '../lib/local-storage';

export const getNewTokensAndUpdateUserStore = async (dispatch, refreshToken) => {
    const axiosInstance = AxiosInstance.getInstanceWithoutToken()

    try {
        const response = await axiosInstance.post('/refresh-token', undefined, {
            params: {
                refresh_token: refreshToken
            }
        })

        await asyncLocalStorage.setItem(AUTH_TOKEN_KEY, response.data.access_token)
        
        dispatch(
            updateUser({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
                userType: response.data.user_type,
                accessGranted: response.data?.access_granted ? true : false,
                userId: response.data._id,
                isAuthenticated: true
             })
        )
    } catch(err) {
        console.error(err)
        toast.error(err?.response?.data?.detail || err?.message)
    }
}