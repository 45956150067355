const CheckResultMessage = ({status = "In Progress", message = "The tasks are being generated."}) => {
    const textColor = status === 'failed' ? '#e74c3c' : '#07bc0c'
    return (
        <div>
            <div className="text-base">
                <div style={{color: textColor}} className="text-black font-bold capitalize">{status}</div>
            </div>
            <p className="text-sm text-[#575b5f]">{message}</p>
        </div>
    )
}
export default CheckResultMessage