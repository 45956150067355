import axios from "axios"
import { asyncLocalStorage, AUTH_TOKEN_KEY } from "../local-storage";

class AxiosService {
    axiosInstance = {};
    axiosInstanceTokenLess = {}

    constructor() {
      this.initInstanceTokenLess()
      this.initInstance();
    }

    initInstance() {
        this.axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 1000 * 60 * 10
        })

        this.axiosInstance.interceptors.request.use(async (config) => {
              const token = await asyncLocalStorage.getItem(AUTH_TOKEN_KEY)
              config.headers.Authorization = `Bearer ${token}`;
        
              return config;
            }
          );

        return this.axiosInstance
    }

    initInstanceTokenLess() {
        this.axiosInstanceTokenLess = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 1000 * 60 * 10
        })

        return this.axiosInstanceTokenLess
    }


    getInstance() {
        return this.axiosInstance || this.initInstance();
    }

    getInstanceWithoutToken() {
        return this.axiosInstanceTokenLess || this.initInstanceTokenLess();
    }
}

const axiosInstance = new AxiosService()
export default axiosInstance