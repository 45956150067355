import { useEffect, useRef, useState } from "react"
import { getBackgroundColorForScore, getColorForScore } from "../../utils"
import showListItem from "../show-list-item"

export const GetSummaryReport = ({summary}) => {
    const divRef = useRef(null)
    const [showDetails, setShowDetails] = useState(false)
    const [width, setWidth] = useState(0)

    const toggle = () => setShowDetails(!showDetails)

    useEffect(() => {
        if(!divRef?.current) return
        setWidth((divRef.current.clientWidth / 10) *  summary.composite_score)
    }, [divRef])

    const backgroundcolor = getBackgroundColorForScore(summary.composite_score)
    const color = getColorForScore(summary.composite_score)
    return (
        <div>
            <div onClick={toggle} role="button">
                <div className="text-base font-semibold">Overall Score: ({summary.composite_score}/10)</div>
                <div style={{backgroundColor: backgroundcolor}} ref={divRef} className="w-full h-5 mt-3">
                    <div style={{backgroundColor: color, width: `${width}px`}} className="bg-[#FF00B8] h-full" />
                </div>
             </div>
            {showDetails && (<div className="flex flex-col gap-3 mt-3">

                <div >
                    <div className="font-medium text-base text-[#828282]">Key Strengths:</div>
                    <ol className="pl-4 text-sm font-light list-decimal">
                        {showListItem(summary.key_strengths)}
                    </ol>
                </div>

                <div >
                    <div className="font-medium text-base text-[#828282]">Critical Improvement Areas:</div>
                    <ol className="pl-4 text-sm font-light list-decimal">
                        {showListItem(summary.critical_improvement_areas)}
                    </ol>
                </div>

                <div>
                    <div className="font-medium text-base">Recommendation:</div>
                    <ol className="pl-4 text-sm font-light list-decimal text-[#828282">
                        {showListItem(summary.next_steps)}
                    </ol>
                </div>
            
            </div>)}
        </div>
    )
}

export const getDocumentAnalysisReport = (documentAnalysis) => {
    return Object.keys(documentAnalysis).map((key) => <DocumentAnalysisCard key={key} name={key} data={documentAnalysis[key]}/>)
}

export const getFragmentaryResponse = (fragmentaryResponse) => {
    return (
        <div>
        <div className="bg-[#F2F2F2] relative mt-5 py-3 px-3 flex flex-col justify-center gap-3">
            <div className="text-base font-semibold">PDF Fragmentary Check Response:</div>
        </div>
        
        <div className="bg-[#F2F2F2] relative py-3 px-3 grid grid-cols-3 justify-center gap-3">
            {getDocumentAnalysisReport(fragmentaryResponse.document_analysis)}
        </div>
        <div className="bg-[#F2F2F2] relative mt-5 py-3 px-3 flex flex-col justify-center gap-3">
            <GetSummaryReport summary={fragmentaryResponse.summary} />
        </div>
        </div>
    )
}

const DocumentAnalysisCard = ({data, name}) => {
    const divRef = useRef(null)
    const [showDetails, setShowDetails] = useState(false)
    const [width, setWidth] = useState(0)

    const toggle = () => setShowDetails(!showDetails)

    useEffect(() => {
        if(!divRef?.current) return
        setWidth((divRef.current.clientWidth / 10) *  data.score)
    }, [divRef])

    const backgroundcolor = getBackgroundColorForScore(data.score)
    const color = getColorForScore(data.score)
    return (
        <div>
            <div onClick={toggle} role="button">
             <div className="capitalize text-base font-semibold">{name.replaceAll('_',' ')} ({data.score}/10)</div>

             <div style={{backgroundColor: backgroundcolor}} ref={divRef} className="w-full h-5 mt-3">
                <div style={{backgroundColor: color, width: `${width}px`}} className="h-full" />
             </div>
             </div>
             
             {showDetails && <div className="flex flex-col gap-3 mt-3">
                <div className="flex flex-col justify-center">
                    <div className="font-medium text-base">Reason:</div>
                    <div className="font-light text-sm text-[#828282]">{data.reason}</div>
                </div>

                <div>
                    <div className="font-medium text-base text-[#828282]">Suggestions:</div>
                    <ol className="pl-4 text-sm font-light list-decimal">
                        {showListItem(data.suggestion)}
                    </ol>
                </div>
            </div>}
        </div>
    )
}