export const showEpics = (epics, addTask, activeEpicIndex) => {
    return epics.map((epic, index) => <Epic key={index} addTask={addTask} epic={epic} index={index} activeEpicIndex={activeEpicIndex}/>)
}

const Epic = ({addTask, epic, index, activeEpicIndex}) => {
    const {epic_name, epic_description} = epic

    const handleClick = () => {
        addTask(index)
    }

    const backgroundColor = activeEpicIndex === index ? '#f2f2f2' : '#FAFAFA'
    const borderColor = activeEpicIndex === index ? '#ff5c00' : '#0D61F7'
    const divBorderColor = activeEpicIndex === index ? '#ff5c00' : '#E3E3E3'
    return (
    <div role="button" style={{backgroundColor: backgroundColor, borderColor: divBorderColor, transition: 'border-color .3s ease-out'}} className="shadow-lg min-w-80 max-w-80 border py-4" onClick={handleClick}>
        <div className="mx-3 flex items-center justify-start mb-3 gap-2">
            <div className="">
                <div className="bg-[#EAD9F8] text-[#913CB9] text-xs font-bold px-2 py-1 rounded">{Number(index + 1)}</div>
            </div>
        </div>

        <div style={{borderColor: borderColor, transition: 'border-color .3s ease-out'}} className="border-l-4 px-3 flex flex-col gap-1">
            <div className="font-medium text-base">{epic_name}</div>
            <div className="font-normal text-xs text-[#828282]">{epic_description}</div>
        </div>

    
    </div>
    )
  }

export default Epic