import { useEffect, useState } from "react"
import { showEpics } from "../../components/epic"
import Task, { TaskDetails } from "../../components/task"
import AxiosInstance from '../../lib/axios/axios-instance';
import {  toast } from 'react-toastify';
import { FEEDBACK_TYPES, LLMs } from '../../types'
import {  useSearchParams } from "react-router-dom";
import CheckResultMessage from "../../components/check-result-message";

const axiosInstance = AxiosInstance.getInstance()

const showPointersText = (dataList) => {
    if(!Array.isArray(dataList)) return (<li>{dataList}</li>)
    return dataList.map(( pointer, idx ) => {
        const {text} = JSON.parse(pointer)

        return <li key={idx}>{text}</li>
    })
  }

const FeedbackAccordion = ({showDetails, toggle, feedback, index}) => {
    const { pointers, user_id, text } = feedback
    return (
        <div className="bg-[#F2F2F2] relative mt-5 flex flex-col justify-center gap-3 w-full">
            <div onClick={toggle} role="button" className="py-3 px-3 truncate text-base font-semibold">
                {Number(index + 1)} : {text ? text : ''}
             </div>

            {showDetails && (<div className="py-3 px-3 flex flex-col gap-3">

                {pointers.length > 0 && <div >
                    <ol className="pl-4 text-sm font-light list-decimal">
                        {showPointersText(pointers)}
                    </ol>
                </div>}

                {text && 
                <div>
                    <div className="font-medium text-base text-[#828282]">Additional Text:</div>
                    <div className="text-sm font-light list-decimal">
                        {text}
                    </div>
                </div>
                }

            {/* {text && 
                <div >
                    <div className="font-medium text-base text-[#828282]">User Id:</div>
                    <div className="text-sm font-light list-decimal">
                        {user_id}
                    </div>
                </div>
                } */}
            
            </div>)}
        </div>
    )
}

const Feedbacks = ({
    feedbackList
}) => {
    const [showDetails, setShowDetails] = useState(-1)

    const toggle = (idx) => {
        if(showDetails === idx)  {
            setShowDetails(-1)
            return
        }
        setShowDetails(idx)
    }

    if(!feedbackList || feedbackList.length === 0) {
        return (
            <div className="mt-5 flex justify-center flex-1">
                <div className="mt-16">No Feedback found.</div>
            </div>
        )
    }

    return (
        <div>
            <div>
                <div className="text-base font-semibold ml-1 mt-1">Feedbacks:</div>
            </div>
            <div>
                {feedbackList.map((feedback, idx) => <FeedbackAccordion index={idx} key={feedback.uuid} feedback={feedback} toggle={()=>toggle(idx)} showDetails={showDetails === idx}/>)}
            </div>
        </div>
    )
}


const ViewFeedback = () => {
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('user_id')
    const projectName = searchParams.get('project_name')

    const [checkingResult, setCheckingResult] = useState(false)
    const [epics, setEpics] = useState(null)
    const [activeEpicIndex, setActiveEpicIndex] = useState(-1)
    const [tasksLength, setTasksLength] = useState(0)
    const [tasks, setTasks] = useState(null)
    const [activeTaskIndex, setActiveTaskIndex] = useState(-1)
    const [details, setDetails] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState(null)
    const [feedbacks, setFeedbacks] = useState(null)

    const addTask = (index) => {
        setActiveEpicIndex(-1);
        setTasks(null)
        setActiveTaskIndex(-1)
        setDetails(null)

        setActiveEpicIndex(index)
        const epic = epics[index]

        let Tasks = []
        epic.features.forEach(feature => {
            feature.tasks.forEach(task => {
                Tasks.push(task)
                task?.subtasks?.forEach(subtask => {
                    Tasks.push(subtask)
                })
            })
        })
    
        setTasks(Tasks)

        if(activeEpicIndex === index) {
            getEpicFeedbacks()
        }
    }

    const showDetails = (taskDetails, index) => {
        if(activeTaskIndex === index) return

        setActiveTaskIndex(index)
        setDetails(taskDetails)
    }

    const getEpicFeedbacks = async () => {
        try {
            if(activeEpicIndex === -1) return

            setIsLoading(true)
            setFeedbacks(null)
            const epic = epics[activeEpicIndex]
            
            const response = await axiosInstance.get("/feedback/filter", {
                params: {
                    type: FEEDBACK_TYPES[0],
                    type_uuid: epic.uuid,
                    project_name: projectName
                }
            })

            setIsLoading(false)
            setFeedbacks(response?.data)
        } catch(err) {
            console.error(err)
            toast.error("Error while fetching the Feedbacks of the Epic.")
            setIsLoading(false)
            setFeedbacks(null)
        }
    }

    useEffect(() => {
        getEpicFeedbacks()
    },[activeEpicIndex])

    const getTaskFeedbacks = async () => {
        if(!projectName) return

        try {
            if(activeTaskIndex === -1) return

            setIsLoading(true)
            setFeedbacks(null)

            const task = tasks[activeTaskIndex]
            const response = await axiosInstance.get("/feedback/filter", {
                params: {
                    type: FEEDBACK_TYPES[1],
                    type_uuid: task.uuid,
                    project_name: projectName
                }
            })
            
            setIsLoading(false)
            setFeedbacks(response?.data)
        } catch(err) {
            console.error(err)
            toast.error("Error while fetching the Feedbacks of the Task.")
            setIsLoading(false)
            setFeedbacks(null)
        }
    }

    useEffect(() => {
        getTaskFeedbacks()
    },[activeTaskIndex])

    const showTasks = (tasks, showDetails, activeTaskIndex, activeEpicIndex, setTasksLength) => {
        return tasks.map((task, index) => <Task key={index} index={index} showDetails={showDetails} task={task} activeTaskIndex={activeTaskIndex} activeEpicIndex={activeEpicIndex}/>)
    }

    const checkResult = async () => {
        if(!userId || !projectName) return

        setEpics(null)
        setErrors(null)
        try {
            setCheckingResult(true)
            const response = await axiosInstance.get("/check-result", {
                params: {
                    project_name: projectName,
                    user_id: userId
                }
            })
            
            if(response?.data?.length === 0 ) {
                setErrors(`No result found for the Project Name: ${projectName}`)
                setCheckingResult(false)
                return
            }

            setEpics(response?.data)
            setCheckingResult(false)
            setErrors(null)
        } catch(err) {
            console.error(err)
            setErrors(err?.response?.data?.detail || err?.message)
            setCheckingResult(false)
        } 
    }

    useEffect(() => {
        checkResult()
    },[])


    if(checkingResult) {
        return (
        <div className="flex flex-1 flex-col gap-3 mt-5 mb-5 sticky top-0 h-[97vh]">
            <div className="border border-dashed flex justify-center flex-1">
                <div className="mt-16">Loading...</div>
            </div>
        </div>
        ) 
    }

    if(errors) {
        return (
        <div className="flex flex-1 flex-col gap-3 mt-5 mb-5 sticky top-0 h-[97vh]">
            <div className="border border-dashed flex justify-center flex-1">
                <div className="mt-16">{JSON.stringify(errors)}</div>
            </div>
        </div>
        ) 
    }

    if(!Array.isArray(epics)) {
        return (<div className="flex flex-1 flex-col gap-3 mt-5 mb-5 sticky top-0 h-[97vh]">
            <div className="border border-dashed flex justify-center flex-1">
                <div className="mt-16 text-center">
                    <CheckResultMessage status={epics?.status === 'success' ? undefined : epics?.status} message={epics?.status === 'success' ? undefined : epics?.message}/>
                </div>
            </div>
        </div>)
    }

    return (
        <div className="px-10">

            {(epics) && epics.length > 0  && (
            <div className="flex flex-row gap-3">
                <div className="flex flex-col  gap-3 mt-5 mb-5">
                    <div className="flex justify-between items-center bg-white sticky top-0 py-5">
                        <div className="text-base font-semibold">Epics: ({epics.length})</div>
                    </div>
                    <div className="max-h-[97vh] overflow-x-auto flex flex-col gap-3">
                        {showEpics(epics, addTask, activeEpicIndex)}
                    </div>
                </div>

                {activeEpicIndex !== -1 ? <div className="flex flex-col  gap-3 mt-5 mb-5">
                    <div className="flex justify-between items-center sticky top-0 pr-5 py-5 bg-white">
                        <div className="text-base font-semibold">Tasks: {tasksLength ? `(${tasksLength})` : ''}</div>
                        <div></div>
                    </div>

                    <div className="max-h-[97vh] overflow-x-auto flex flex-col gap-3">
                        {showTasks(tasks, showDetails, activeTaskIndex, activeEpicIndex, setTasksLength)}
                    </div>
                </div> : <Feedbacks feedbackList={feedbacks}/>}

                {(activeTaskIndex !== -1) ?<div className="flex flex-col flex-1 gap-3 mt-5 mb-5">
                    <div className="flex justify-between items-center pr-5 py-5 bg-white sticky top-0">
                        <div className="text-base font-semibold">Details:</div>
                        <div></div>
                    </div>

                    <div className="max-h-[97vh] overflow-x-auto flex flex-col gap-3">
                        <div className="flex flex-col flex-wrap">
                            <TaskDetails ticket={details} activeTaskIndex={activeTaskIndex} activeEpicIndex={activeEpicIndex}/>
                        </div>

                            <Feedbacks feedbackList={feedbacks}/>
                    </div>
                    
                </div> : activeEpicIndex !== -1 && (
                        <div className="flex flex-1 flex-col gap-3 mt-5 mb-5 sticky top-0 h-[97vh]">
                        <div className="flex justify-between items-center pr-5 py-5 bg-white">
                            <div className="text-base font-semibold"></div>
                            <div></div>
                        </div>
                        <div className="mt-5 border border-dashed flex flex-1 px-3 flex-col">
                            <Feedbacks feedbackList={feedbacks}/>
                        </div>
                    </div> 
                    )}

            </div>
            )}
        </div>
    )
}

export default ViewFeedback